$(function() {
    $('.meet-the-team__thumbnail').click(function(){
        self = this;
        parent = $(self).parent();
        openPanel();
    })
});

function openPanel() {

    description_height = $(parent).find('.meet-the-team__description').outerHeight();

    $('.meet-the-team__panel').not(parent).removeClass('meet-the-team__panel--active');

    setTimeout(function(){
        $('.meet-the-team__panel').css('padding-bottom', '');
        $(parent).css('padding-bottom', description_height);
        $(parent).toggleClass('meet-the-team__panel--active');
    }, 300);

}
